import React,{useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

//react icons
import { MdClose } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";


//hooks
import { useLogout } from '@/src/hooks/useLogout';

//comopnents
import { ProfilePictureIcon } from '@/src/atoms-new/ProfilePictureIcon';


//redux
import { useAppDispatch } from '@/src/hooks/useDispatch';
import { useAppSelector } from '@/src/hooks/useSelector';
import { setLanguage, updateLanguage } from '@/store/slices/settingsSlice';

export type LanguageChoices= 'en' | 'jp'

interface UserMenuProps {
    hideModal: () => void;
    l?: 'en' | 'jp'
}

export const UserMenu = ({
    hideModal, 
    l='jp'
}:UserMenuProps) => {
    const dispatch = useAppDispatch();
    const logout = useLogout();
    const router = useRouter();

    const userData = useAppSelector(state => state.user).data
    const {token, email, is_organizer, profilePicture} = userData.user
    const {firstName, lastName} = userData.basic_profile

    const isUser = token ? true : false
    const isOrganizer = isUser && is_organizer ? true : false

    //logout handler
    const logoutHandler = () => {
        if (token) {
            logout()
        }
        hideModal()
    }

    const closeHandler = (e:React.SyntheticEvent) => {
        e.stopPropagation();
        // e.preventDefault();
        hideModal()
    }
    
    // language nested dropdown
    const [isLanguageOpen, setLanguageOpen] = useState(false);
    const toggleLanguageDropdown = () => {
        setLanguageOpen(!isLanguageOpen);
    }
    // Handle language change and sync with router
    const handleLanguageChange = (newLanguage:LanguageChoices) => {
        if (newLanguage !== l) {
            //check if user logged or not
            let loggedIn = false
            if ('user' in userData && 'token' in userData.user) {
                loggedIn = true
            }
            // logged in: set to user settings in backend data
            if (loggedIn) {
                dispatch(updateLanguage({language: newLanguage}))
            // not logged in: only set state in redux
            } else {
                dispatch(setLanguage(newLanguage))
            }
            setLanguageOpen(false);
            hideModal()
        }
    };

    return (
        <div className='flex flex-col w-full h-full gap-0'>
            <div className='w-full flex justify-end '>
                <div className='cursor-pointer' onClick={hideModal}>
                    <MdClose size={28}/>
                </div>
            </div>

            <div className='flex flex-row gap-5 pb-3 border-b'>
                <div className='w-[75px] aspect-square bg-gray-200 rounded-full'>
                    <ProfilePictureIcon 
                        height='h-full'
                        image={profilePicture}
                    />
                </div>
                <div className='flex flex-col gap-1 justify-center h-full'>
                    <p className='text-sm text-gray-500'>{lastName} {firstName}</p>
                    <p className='text-sm'>{email}</p>
                </div>
            </div>

            <div className='flex flex-col gap-0'>
                <Link href='/search-lessons' className='p-4 w-full cursor-pointer hover:bg-gray-100 border-b'>
                    <p className='text-md text-gray-600'>Search Lessons</p>
                </Link>
                {/* <Link href='/search-organizers' className='p-4 w-full border-b cursor-pointer hover:bg-gray-100'>
                    <p className='text-md text-gray-600'>Search Organizers</p>
                </Link> */}
                <Link href='/user/reservations' className='p-4 w-full cursor-pointer hover:bg-gray-100'>
                    <p className='text-md text-gray-600'>Bookings</p>
                </Link>
                <Link href='/user/purchases' className='p-4 w-full cursor-pointer hover:bg-gray-100'>
                    <p className='text-md text-gray-600'>Purchases</p>
                </Link>
                <Link href='/user/following' className='p-4 w-full cursor-pointer hover:bg-gray-100'>
                    <p className='text-md text-gray-600'>Following</p>
                </Link>
                <Link href='/user/messages' className='p-4 w-full cursor-pointer hover:bg-gray-100'>
                    <p className='text-md text-gray-600'>Messages</p>
                </Link>
                <Link href='/user/settings/profile' className='p-4 w-full border-b cursor-pointer hover:bg-gray-100'>
                    <p className='text-md text-gray-600'>Settings</p>
                </Link>

                <div className='relative'>
                    <div 
                        onClick={toggleLanguageDropdown}
                        className="
                            flex items-center justify-between w-full px-4 py-2 
                            font-semibold text-[16px]
                            text-gray-700 hover:bg-gray-100 hover:text-gray-900
                            block px-4 py-2 
                        ">
                        Language
                        <IoIosArrowForward size={18}/>
                    </div>
                    {/** LANGUAGE CHOICES */}
                    <ul className={` absolute botom-0
                        z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-28 text-gray-700 w-full
                        ${isLanguageOpen ? '' : 'hidden'}
                    `}>
                        <li>
                            <p 
                                onClick={l!='en' ? () => handleLanguageChange('en') : undefined}
                                className={`
                                    text-sm block px-4 py-2 hover:bg-gray-100 
                                    ${l!='en' ? 'cursor-pointer' : 'font-bold'}
                                `}>English
                            </p>
                        </li>
                        <li>
                            <p 
                                onClick={l!='jp' ? () => handleLanguageChange('jp') : undefined}
                                className={`
                                    text-sm block px-4 py-2 hover:bg-gray-100
                                    ${l!='jp' ? 'cursor-pointer' : 'font-bold'}
                                `}>日本語
                            </p>
                        </li>
                    </ul>
                </div>

                {   isOrganizer ? (
                    <Link href='/organizer/dashboard/home' className='p-4 w-full cursor-pointer hover:bg-gray-100'>
                        <p className='text-md text-gray-600'>Switch to Instructor</p>
                    </Link>
                ) : (
                    <Link href='/join-ambition/organizer' className='p-4 w-full cursor-pointer hover:bg-gray-100'>
                        <p className='text-md text-gray-600'>Become an Instructor</p>
                    </Link>
                )}

                <div className='mt-3 p-3 w-full flex justify-center border border-gray-600 rounded-lg cursor-pointer hover:bg-gray-100' onClick={logoutHandler}>
                    <p className='text-md text-gray-600'>Logout</p>
                </div>

            </div>
        </div>

    )
}