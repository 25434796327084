//packages
import React,{useEffect, useState, useRef} from 'react';
import { useRouter } from 'next/router';


import { IoSearch } from "react-icons/io5";
import { MdOutlineBookmarkBorder } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
//redux
import { useAppSelector } from '@/src/hooks/useSelector';


//container
import { FullDrawerContainer } from '../../../components/containers/side-drawer-container/fullDrawerContainer';

//local components
import { UserMenu } from './UserMenu';
import { GuestMenu } from './GuestMenu';


interface UserMobileNavFooterProps    {
}

export const UserMobileNavFooter = ({
}: UserMobileNavFooterProps) => {
    const router = useRouter();
    const currentUrl = router.asPath    
    const {action} = router.query
    //language translation settings
    const l = useAppSelector(state => state.settings).data.language


    const userData = useAppSelector(state => state.user).data
    const {user, basic_profile, user_profile, instructor_profile} = userData
    const {token} = user


    /** menu visible state */
    const [menuVisible, setMenuVisible] = useState(false)
    const openMenuHandler = () => {
        // if (token) {
        setMenuVisible(true)
        // } else {
        //     router.push('/auth/login')
        // }
    }


    // hide drawer on route change
    useEffect(() => {
        const handleRouteChange = () => { setMenuVisible(false)};
        // Subscribe to route change events
        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            // Cleanup listener on unmount
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router]);


    /**
     * TEXT TRANSLATIONS
    */
    const text:any = {
        search:{
            en:'Search',
            jp:'検索'
        },
        following:{
            en:'Following',
            jp:'気に入り'
        },
        booking:{
            en:'Booking',
            jp:'予約'
        },
        menu:{
            en:'Menu',
            jp:'メニュー'
        },
    }


    return (
        <>
        <div className={`sticky bottom-0 bg-white w-full h-[70px] drop-shadow-sm flex items-center relative border-t`}>
            <nav className='flex flex-row h-full w-full justify-between items-center'>

                {/** NAV ITEMS */}
                <div
                    onClick={() => router.push('/search-lessons')} 
                    className={`w-full h-full flex flex-col gap-1 items-center justify-center cursor-pointer pt-1
                    ${currentUrl.includes('/search-lessons') ? 'text-prime-orange-dark' : 'text-gray-500'}
                `}>
                    <IoSearch size={20} className=''/>
                    <p className='text-sm'>{text['search'][l]}</p>
                </div>
                <div
                    onClick={() => router.push(token ? '/user/following' : '/auth/signup')} 
                    className={`w-full h-full flex flex-col gap-1 items-center justify-center cursor-pointer pt-1
                    ${currentUrl.includes('/user/following') ? 'text-prime-orange-dark' : 'text-gray-500'}
                `}>
                    <MdOutlineBookmarkBorder size={20} className=''/>
                    <p className='text-sm'>{text['following'][l]}</p>
                </div>
                <div
                    onClick={() => router.push(token ? '/user/reservations' : '/auth/signup')} 
                    className={`w-full h-full flex flex-col gap-1 items-center justify-center cursor-pointer pt-1
                    ${currentUrl.includes('/user/reservations') ? 'text-prime-orange-dark' : 'text-gray-500'}
                `}>
                    <FaRegCalendarAlt size={20} className=''/>
                    <p className='text-sm'>{text['booking'][l]}</p>
                </div>
                <div
                    onClick={openMenuHandler} 
                    className={`w-full h-full flex flex-col gap-1 items-center justify-center cursor-pointer pt-1
                        text-gray-500
                `}>
                    <HiMenu size={20} className=''/>
                    <p className='text-sm'>{text['menu'][l]}</p>
                </div>

            </nav>
        </div>
        <FullDrawerContainer
            isOpen={menuVisible}
            onClose={() => setMenuVisible(false)}
        >
            {   token ? (
                <UserMenu l={l} hideModal={() => setMenuVisible(false)}/>
            ) : (
                <GuestMenu hideModal={() => setMenuVisible(false)}/>
            )}
        </FullDrawerContainer>
        </>
    )
}